<template>
  <div>
    <mt-button class="mb15">
      <el-button @click="onAddBtnClick" type="primary">新增</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-table
      ref="saleTypeTable"
      :data="saleTypeList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      style="width: 100%;">
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="名称">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="needGenerate"
        label="生成数据">
        <template slot-scope="scope">
          <el-tag :type="scope.row.needGenerate === 0 ? 'info' : 'primary'">{{ scope.row.needGenerate === 0 ? '否' : '是' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.needGenerate === 1" class="item" effect="dark" content="数据配置" placement="top">
            <el-button @click.stop="onConfigBtnClick(scope.row)" type="primary" icon="el-icon-set-up" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="编辑" placement="top">
            <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="saleTypeTotal"
      class="pagination">
    </el-pagination>

    <el-dialog :title="model.id === 0 ? '新增品类' : '编辑品类'" :visible.sync="dialogVisible" width="50%">
      <el-form :rules="rules" ref="dataForm" :model="model" label-position="left" label-width="100px" class="data-form"
               style='width: 500px; margin:0 auto;'>
        <el-form-item label="名称" prop="name">
          <el-input v-model="model.name"></el-input>
        </el-form-item>
        <el-form-item label="自动生成数据" prop="needGenerate">
          <el-switch v-model="model.needGenerate" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editSupplier">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="`${model.name}-数据配置`" :visible.sync="configDialogVisible" width="70%">
      <el-form :rules="rules" ref="configForm" :model="configForm" label-position="left" label-width="70px" class="data-form">
        <el-row v-for="(config, index) in configForm.list" :key="config.id" :gutter="20">
          <el-col :span="3">
            <el-form-item label="" label-width="0">
              <div>{{ config.fieldName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="基数" :prop="'list.' + index + '.base'" :rules="{ required: true, message: '基数不能为空', trigger: 'blur' }">
              <el-input v-model="config.base" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="上浮" :prop="'list.' + index + '.floatUp'" :rules="{ required: true, message: '上浮不能为空', trigger: 'blur' }">
              <el-input v-model="config.floatUp" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="下浮" :prop="'list.' + index + '.floatDown'" :rules="{ required: true, message: '下浮不能为空', trigger: 'blur' }">
              <el-input v-model="config.floatDown" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="小数位" :prop="'list.' + index + '.scale'" :rules="{ required: true, message: '小数位不能为空', trigger: 'blur' }">
              <el-input-number v-model="config.scale" :min="0" :max="2" :step="1"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="configDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateConfig">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {apiGetSaleTypeList, apiEditSaleType, apiDeleteById, apiGetDetailConfigById, apiUpdateDetailConfig} from '../../api/saleType'
  import {tableMixin} from '../../mixins/table'

  export default {
    name: 'TraceSaleType',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      return {
        saleTypeList: [],
        saleTypeTotal: 0,
        dialogVisible: false,
        configDialogVisible: false,
        model: {
          id: 0,
          name: '',
          needGenerate: 1 // 是否需要自动生成数据，默认1：需要
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}]
        },
        configForm: {
          list: []
        }
      }
    },
    activated() {
      this._getSaleTypeList()
    },
    methods: {
      onConfigBtnClick(row) {
        this.model = Object.assign({}, this.model, row)
        this.configDialogVisible = true
        this._getSaleTypeDetailConfig(row.id)
      },
      updateConfig() {
        this.$refs['configForm'].validate((valid) => {
          if (valid) {
            apiUpdateDetailConfig(this.configForm.list).then(({data}) => {
              this.configDialogVisible = false
              this.$message({
                type: 'success',
                message: '数据配置成功'
              })
            })
          }
        })
      },
      onAddBtnClick() {
        this.dialogVisible = true
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this._clearModel()
        this.dialogVisible = true
        this.model = Object.assign({}, this.model, row)
      },
      onRefreshBtnClick() {
        this._getSaleTypeList()
      },
      editSupplier() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            apiEditSaleType(this.model).then(({data}) => {
              this.pageIndex = 1
              this._getSaleTypeList()
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: this.model.id === 0 ? '添加成功' : '修改成功'
              })
              this._resetModel()
            })
          }
        })
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该品类？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getSaleTypeList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getSaleTypeList()
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      _resetModel() {
        this.model = {
          id: 0,
          name: '',
          needGenerate: 1
        }
      },
      _getSaleTypeList(pageIndex = this.pageIndex) {
        apiGetSaleTypeList(pageIndex).then(({data}) => {
          this.saleTypeList = data.data
          this.saleTypeTotal = data.total
        })
      },
      _getSaleTypeDetailConfig(id) {
        apiGetDetailConfigById(id).then(res => {
          this.configForm.list = res.data
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
